import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function Aside({ children }: Props) {
  return (
    <div className="row-auto xl:block xl:col-span-4">
      <div className="sticky top-4">{children}</div>
    </div>
  );
}
