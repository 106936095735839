import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function Root({ children }: Props) {
  return (
    <div className="min-h-screen h-full container mx-auto p-4">
      <div className="grid grid-cols-1 xl:grid-cols-12 gap-8">{children}</div>
    </div>
  );
}
